import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import Sidebar from './components/Sidebar';
import About from './pages/About';
import Index from './pages/Index';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import NotFound from './pages/NotFound';
import Page153107011 from './pages/Page153107011';



/**
 * Main page function for the appplication. Routing is done here.
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*} 
 */
function Main(props) {
  return (
    <Router>
      <ScrollToTop />
      <Sidebar />
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/about" component={About} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/contact" component={Contact} />
        <Route path="/blog/:id" component={Blog} />
        <Route path="/blog/" component={Blog} />
        <Route path="/blog" component={Blog} />
        <Route path={["/153107011", "/153107011.php"]} component={Page153107011} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}
export default Main;