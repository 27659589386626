import React from 'react';
/**
 * Generates a corrent Emoji
 * @author Sean McPherson
 */
const Emoji = props => (
    <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
        onClick={props.onClick? props.onClick : ()=>{}}
        style={props.style? props.style : ""}
    >
        {props.symbol}
    </span>
);
export default Emoji;