import React from 'react';
import { IP } from "./GLOBALVAR";
import Main from './Router';


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      IP: IP,
    }
  }

  render() {
    return (
      <Main
        IP={this.state.IP}
      ></Main>
    )
  }
}

export default App;