import React from 'react'; //useEffect 
import { Link } from "react-router-dom";


/**
 * Generates Index
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*} 
 */
function Index(props) {
  return (
    <main>
      <section id="welcome" className="indexMainSections">
        <img alt="welcome" src="images/welcome.jpg" />
        <div className="bannerText">
          <h2 className="title2">Welcome</h2>
          <h4 className="title4">Welcome to my website!</h4>
        </div>
      </section>
      <section id="about" className="indexMainSections">
        <Link to="/about">
          <img alt="about" src="images/about.jpg" />
          <div className="bannerText">
            <h2 className="title2">About</h2>
            <h4 className="title4">Robert Boudewijn, a web_development student.</h4>
          </div>
        </Link>
      </section>
      <section id="portfolio" className="indexMainSections">
        <Link to="/portfolio" >
          <img alt="portfolio" src="images/portfolio.jpg" />
          <div className="bannerText">
            <h2 className="title2">Portfolio</h2>
            <h4 className="title4">Projects I have worked on.</h4>
          </div>
        </Link>
      </section>
      <section id="contact" className="indexMainSections">
        <Link to="/contact">
          <img alt="contact" src="images/contact.jpg" />
          <div className="bannerText">
            <h2 className="title2">Contact</h2>
            <h4 className="title4">Want to say hi?</h4>
          </div>
        </Link>
      </section>
    </main>


  );
}
export default Index;