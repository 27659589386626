import React from 'react'; //useEffect 
import { Link } from 'react-router-dom';


/**
 * Generates a 404 not found page
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*} 
 */
function NotFound(props) {
  return (
    <main className="normal" id="start">
      <h2 className="title2">Whoopsie, we couldn't find anything here.</h2><br />
      <p>The server doesn't know what to find here. Do you? If we work together maybe we can fix this.</p>
      <p>
      <Link to="/contact" className="link">Contact me</Link> or lets go back <Link to="/" className="link">home</Link>
      </p>
    </main>

  );
}
export default NotFound;