import React, { useState, useEffect } from 'react'; // 
import { Link } from "react-router-dom";
import Emoji from './Emoji';

/**
 * Generates sidebar
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*} 
 */
function Sidebar(props) {
  const [showNav, setShowNav] = useState("hidden")
  const [mode, setMode] = useState((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? "dark" : "light");

  /**
   * Opens or closes the menu
   *
   * @author Robert Boudewijn
   * @date 2021/04/09
   */
  function toggleMenu() {
    if (showNav === "hidden") {
      setShowNav("");
    } else {
      setShowNav("hidden");
    }
  }

  /**
   * Toggels darkmode on or off
   *
   * @author Robert Boudewijn
   * @date 2021/04/13
   */
  function toggleMode() {
    if (mode === "dark") {
      setMode("ligth")
    } else {
      setMode("dark")
    }
  }

  /**
   * generates a darkmode button
   *
   * @author Robert Boudewijn
   * @date 2021/04/13
   * @return {*} 
   */
  function modeButton() {
    if (mode === "dark") {
      return <Emoji symbol="☀️" label="Sun" onClick={toggleMode} style={{cursor: "pointer"}} />
    }
    return <Emoji symbol="🌔" label="Moon" onClick={toggleMode} style={{cursor: "pointer"}}/>
  }

  useEffect(() => {
    document.getElementById("body").className = mode;
  }
    , [mode]
  )

  return (
    <aside id="header" className="">
      <nav>
        <div id="menuButton" onClick={toggleMenu} >
          <p id="line1" className={showNav}></p>
          <p id="line2" className={showNav}></p>
          <p id="text">MENU</p>
        </div>
        <div className={"navlist " + showNav}>
          <Link to="/" id="navhome" onClick={toggleMenu}>Home</Link>
          <Link to="/about" id="navabout" onClick={toggleMenu}>About</Link>
          <Link to="/portfolio" id="navportfolio" onClick={toggleMenu}>Portfolio</Link>
          <Link to="/contact" id="navcontact" onClick={toggleMenu}>Contact</Link>
          <Link to="/blog" id="navblog" onClick={toggleMenu}>Blog</Link>
          {modeButton()}
        </div>
      </nav>
      <div className="headers">
        <Link to="/" id="navhome">
          <h1 className="title1">Robert Boudewijn</h1>
        </Link>
        <h3 className="title3"> Student at HAN University of applied sciences.</h3>
      </div>
      <div className="copyright">
        <p onClick={toggleMode} >Copyright © 2020 Robert Boudewijn</p>
      </div>
    </aside>

  );
}
export default Sidebar;