import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Markdown from 'markdown-to-jsx';
import  plantumlEncoder from 'plantuml-encoder';
import "./Blog.scss"

function Blog(props) {
  const parameters = useParams();
  let [data, setData] = useState({});
  let [id, setId] = useState(getId());

  useEffect(() => {
    props.history.push({
      pathname: '/blog/' + id
    });
    setData(dataset[id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function getId() {
    // gets id if id is set and a number, else takes the last inserted one
    return (Number)(parameters.id && !isNaN(parameters.id) && dataset[parameters.id] !== undefined ? parameters.id : dataset.length - 1);
  }

  function updateId(direction){
    if(direction === +1){
      if(id < dataset.length -1){
        let newId = (Number)(id) +1;
        setId(newId);
      }
    } else {
      if(id > 0){
        let newId = (Number)(id) -1;
        setId(newId);
      }
    }
  }

  return (
    <main className="normal blog" id="start">
      <div className='navigation' style={{display: "flex"}}>
        
      <Link onClick={() => { updateId(-1); }}>Previous</Link>

      <select onChange={(e)=> setId(e.target.value)}>
        {dataset.map((element, index) => <option key={index} id={index} selected={(id === index)} value={index}>{element.dayTime + " | " + element.title}</option>)}
      </select>

      <Link onClick={() => { updateId(+1); }}>Next</Link>
      </div>
      <br />
      <br />


      {(data.title ?
        <>
          <h2 className='title2'>{data.title}</h2>
          <p className=''><strong>Posted on: {data?.dayTime} | <i>{data.location}</i></strong></p>
          <br />
          <p><Markdown 
          // image should have with 100% and height auto
          options={{
            overrides: {
              img: {
                props: {
                  style: {
                    width: "70%",
                    height: "auto"
                  }
                }
              },
              h2: {
                props: {
                  className: "title3",
                  style: {
                    marginTop: "1em",
                  }
                }
              }
                  
            }
          }}


          >{data.content}</Markdown></p>
          <br />
          <br />
          <p><i>- {data.author}</i></p>
        </>
        : <></>)
      }
    </main>
  );
}
export default Blog;


let dataset = [
  { title: "Welcome to this blog", dayTime: "16-05-2023 21:45", location: "Arnhem region - NLD", author: "Robert Boudewijn", content: "Welcome to this new blog! Here I want to log updates about projects that I am working on. The idea is to tell every day what I've done, what problems I've run into and how I fixed them. <br><br> Lets hope that idea holds up haha" },
  { title: "Tweater - Project goals, domain and database design", dayTime: "03-06-2023 23:23", location: "Arnhem region - NLD", author: "Robert Boudewijn", content: 
`
As I wrap up this blog post, I am brimming with excitement for the start of my new summer project. The journey ahead involves building a new social media platform, but it's not just any ordinary project. It's an opportunity for me to explore and experiment with different server languages, load balancing techniques, and cutting-edge web technologies. <br>

## Project goals 
The main goal of this project is to create a web application that seamlessly interacts with multiple web servers built on different programming languages. To achieve this, I plan to develop two front-end applications that will interact with various back-end systems including NodeJS (Express), Python (Flask), Java (Spring), PHP (Laravel), C# (.NET Core 6.0), and I'll even attempt to explore the possibilities with C++. <br><br>

With the integration of a load balancer built with Nginx, I aim to ensure that users experience a consistent performance regardless of the underlying back-end system. The load balancer will be configured to utilize the round-robin algorithm, distributing incoming requests evenly across the available servers. <br>

## Domain and user experience
The domain of this project revolves around a social media platform, where users can create accounts, post messages, and engage with each other through likes and comments. The highlight of the platform is the timeline feature, which will display a curated list of messages from the accounts that the user follows. This setup aims to foster a sense of community and facilitate meaningful interactions among users. <br><br>

![Domain modal](http://www.plantuml.com/plantuml/svg/`+plantumlEncoder.encode(`
@startuml
class User {
  + id: int
  + username: string
  + email: string
  + profilePicture: string
}

class Post {
  + id: int
  + user_id: int
  + content: string
  + created_at: datetime
}

class Comment {
  + id: int
  + user_id: int
  + post_id: int
  + content: string
}

class Like {
  + id: int
  + user_id: int
  + post_id: int
}

class Follow {
  + id: int
  + user_id: int
  + following_id: int
}

User "1" -- "*" Post
User "1" -- "*" Comment
User "1" -- "*" Like
User "1" -- "*" Follow

Post "1" -- "*" Comment
Post "1" -- "*" Like


@enduml
`)+`)

Notably, this application will adopt a passwordless login system. Users will be able to log in using their username and email address. Upon verification, they will receive an email containing a secure login link, valid for a limited time. This approach adds an extra layer of security by mitigating the risks associated with brute force attacks and data leaks.

## database and dockerization
All back-end systems will interact with a single PostgreSQL database. While there is potential for implementing a load-balanced database in the future, for now, I will focus on utilizing a single database to streamline development.


![Database design](http://www.plantuml.com/plantuml/svg/`+plantumlEncoder.encode(`
@startuml
Class User {
  + id: int identity
  + username: varchar 255
  + email: varchar 255
  + profilePicture: string
  + status: enum('active', 'inactive', 'admin', 'banned')
  + created_at: timestamptz default now()
}

Class Token {
  + id: int identity
  + user_id: int
  + token: varchar 255 
  + ip: varchar 255
  + expires_at: timestamptz
}

Class Post {
  + id: int identity
  + user_id: int
  + content: varchar 255
  + created_at: timestamptz default now()
}

Class Comment {
  + id: int identity
  + user_id: int
  + post_id: int
  + content: varchar 255
  + created_at: timestamptz default now()
}

Class Like {
  + id: int identity
  + user_id: int
  + post_id: int
  + created_at: timestamptz default now()
}

Class Follow {
  + id: int identity
  + user_id: int
  + following_id: int
  + created_at: timestamptz default now()
}

User -- Post : user.id = post.user_id
User -- Comment : user.id = comment.user_id
User -- Like : user.id = like.user_id
User -- Follow : user.id = follow.user_id
User -- Token : user.id = token.user_id

Post -- Comment : post.id = comment.post_id
Post -- Like : post.id = like.post_id

@enduml
`)+`)

Moreover, to simplify deployment and enhance scalability, I plan to dockerize the entire application. Each server, including the load balancer and the database, will be running in separate Docker containers. This containerization approach will facilitate easy deployment and portability of the application to various server environments.


<!--## Front-end goals
With the front-end I wouldlike to learn more about new web technologies. I would like to learn more about the following technologies:
- WebSql
- WebWorkers
-->

<br> 
## Conclusion
In conclusion, this summer project presents an exciting opportunity to explore new horizons in web development. By diving into different server languages, load balancing techniques, and emerging web technologies such as WebSQL and WebWorkers, I aim to broaden my knowledge and skills in this ever-evolving field. <br><br>

I invite you to follow my progress and join me on this journey as I document my experiences, challenges, and breakthroughs. Together, we can embrace the future of web development and unlock the immense potential that lies within. Stay tuned for future updates as I work towards transforming this project into a fully functional social media platform. Let's embark on this adventure together and shape the future of digital connectivity.

  ` },
  { title: "Tweater - Setting up the development environment (docker, nginx, database)", dayTime: "08-06-2023 23:23", location: "Arnhem region - NLD", author: "Robert Boudewijn", content:
`

Today, I started setting up the development environment for my summer project with great enthusiasm. The first step was creating a Git repository to manage my code. Additionally, I created a Docker Compose file that includes two basic services. The first service is Nginx, which acts as a load balancer and also serves a simple front-end on '/'. The second service is PostgreSQL, for which I set up a volume to ensure persistent data storage. I also added an initialization script to create the necessary tables and relationships.<br><br>

To enable communication between the services and improve clarity in the Nginx configuration, I created a separate network. This allows me to use service names like 'nodejs:8080' within the Nginx configuration instead of 'host.docker.internal:8080'. I also added a rule to the Nginx configuration to rewrite '/api/' requests to '/'.<br><br>

This resulted in the following Docker Compose configuration:<br><br>

<code>
version: '3.8'

services:

    nginx:
      build: 
        context: ./
        dockerfile: ./loadBalancer/Dockerfile
      ports:
        - "80:80"
        - "443:443"
      networks:
        - my-network
      restart: always

    psql:
      image: postgres:15.3
      environment:
        POSTGRES_USER: zomerProject
        POSTGRES_PASSWORD: Passw0rd
        TZ: 'Europe/Amsterdam'
        PGTZ: 'Europe/Amsterdam'
      ports:
        - "5432:5432"
      volumes:
        - ./database/psql_data:/var/lib/postgresql/data
        - ./database/structure.sql:/docker-entrypoint-initdb.d/create_tables.sql
      networks:
        - my-network
      restart: always

    nodejs:
      build: /nodeJS
      ports:
        - "3000:8080"
      networks:
        - my-network
      restart: always

    python:
      build: /python
      ports:
        - "3001:8080"
      networks:
        - my-network
      restart: always

    networks:
      my-network:
        driver: bridge

</code>

<br><br>
As you can see there are two services for the back-end, one for NodeJS and one for Python. These services are just basic servers that respond "helloworld". This was to make sure that the load balancer was working correctly. 
The corresponding Nginx configuration looks like this:
<br><br>

<pre>

worker_processes 1;

events { worker_connections 1024; }

http {
  proxy_read_timeout 300;
  proxy_connect_timeout 300;
  proxy_send_timeout 300;

  # load balancer that randomly distributes requests to the upstream servers
  upstream app {
    random;
    server nodejs:8080;
    server python:8080;
  }

  server {
    listen 443 ssl;
    ssl_certificate /etc/nginx/certs/server.crt;
    ssl_certificate_key /etc/nginx/certs/server.key;

    listen 80;
    server_name localhost;

    location /api {
      proxy_pass http://app;

      # rewrite /api to / so that the app can handle the request
      rewrite ^/api/(.*)$ /$1 break;
    }

    location / {
      root /usr/share/nginx/html;
    } 
  } 
}
</pre>


<br><br>
I am already looking forward to continuing the development of my social media platform. Stay tuned for updates as I will regularly share my progress and the challenges I encounter. Until next time!

`},
  { title: "Tweater - Got the first back-end working", dayTime: "19-06-2023 23:23", location: "Arnhem region - NLD", author: "Robert Boudewijn", content:
`
It has been around a week since my last post and a lot has happend. During this time I've added a few compontents to the application. I started with a simple mail server that uses phpmailer. This will be used to send updates and login codes to every user. I also added a simple login system in nodejs that uses random generated tokens. This will be used to authenticate users and to keep track of their sessions. <br><br> 

The front-end is also updated to support: login, register, posting, commenting, liking and following. The front-end is still very basic and is the next thing I will be working on. <br><br>

During developping the database has been changed slightly. The following diagram shows the current database structure. <br><br>

![](https://www.plantuml.com/plantuml/svg/`+plantumlEncoder.encode(`

@startuml

entity "users" {
  * id : int
  * username : varchar(255)
  * email : varchar(255)
  * profile_picture : varchar(255)
  * status : user_status
  * sign_in_code : numeric(6)
  * created_at : timestamptz
}

entity "token" {
  * id : int
  * user_id : int
  * token : varchar(255)
  * expires_at : timestamptz
}

entity "posts" {
  * id : int
  * user_id : int
  * content : varchar(255)
  * created_at : timestamptz
}

entity "comments" {
  * id : int
  * user_id : int
  * post_id : int
  * content : varchar(255)
  * created_at : timestamptz
}

entity "likes" {
  * id : int
  * user_id : int
  * post_id : int
  * created_at : timestamptz
}

entity "follows" {
  * id : int
  * user_id : int
  * following_id : int
  * created_at : timestamptz
}

users ||-o{ token
users ||-o{ posts
users ||-o{ comments
users ||-o{ likes
users ||-o{ follows

@enduml
`)+`)
<br><br>

The main changes of the database are table names and the addition of the user_status enum. The user_status enum is used to keep track of the status of a user. The status can be: active, inactive, banned or admin. The status is used to determine if a user can login or not. <br><br>



`},
];