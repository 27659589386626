import React from "react"

/**
 *generates contact
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*} 
 */
function Contact(props) {
  return (
    <main className="normal" id="start">
      <img alt="contact" src="images/contact.jpg" />
      <h2 className="title2">Contact me!</h2><br />
      <p>Hi, I am Robert, the owner of the website. If you would like to know more about me or just send a message you can do so using the following email:</p>
      <p><a href="mailto:Robert@robertboudewijn.nl" className="link">robert@robertboudewijn.nl</a></p><br />
      <p>I am also active on instagram if you would like to follow me there:</p>
      <p><a href="https://instagram.com/nxttx" className="link" target="_blank" rel="noreferrer">Instagram @nxttx</a></p><br />
      <p>If you want to follow me on my strava account, for all of my sport activity's. Press on the following link:</p>
      <p><a href="https://www.strava.com/athletes/19646647" className="link" target="_blank" rel="noreferrer">Strava Robert Boudewijn</a></p>
    </main>

  )
}

export default Contact