import React from 'react'; //useEffect 



/**
 * Page for Karlijn
 *
 * @author Robert Boudewijn
 * @date 2021/05/06
 * @param {*} props
 * @return {*} 
 */
function Page153107011(props) {
  
  return (
    <main className="normal" id="start">
      <h2 className="title2">/153107011</h2><br />
        <p>Netjes het is je gelukt! Misschien was het een beetje lastig. Met het omwisselen van hexgetallen naar gewoon
            onze
            decimale getallen. Of had je dat al vrij snel door?
            Was de moeite juist het observeren? Hahaha. Maar het belangrijkste is wel dat het je is gelukt. En dat je de
            tijd er voor hebt genomen. Of eigenlijk voor mij :). Iemand die je eigenlijk nog niet eens langer kent dan 2
            a 3
            weken.
        </p><br/>
        <p>Ik vind het uit eindelijk maar knap. Niet iedereen kan dit zo 123. En daarom wil ik je vertellen dat ik je
            heel erg leuk vind. En nu al uitkijk naar zondag, wanneer ik je weer kan zien {"<"}3.
        </p><br/> <p></p><br/>
        <p>TL;DR Knap dat het je gelukt is, ik vind je lief {"<"}3.</p>
      
   </main>

  );
}
export default Page153107011;