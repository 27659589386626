import React from "react"; //useEffect
import { Link } from "react-router-dom";

/**
 * Generates about page
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*}
 */
function About(props) {
  const skills = [
    "Mapping business processes and system applications based on UML",
    "Java and JAX-RS",
    "SQL, mysql and MongoDB",
    "HTML, CSS, PHP",
    "React, redux, nodeJS",
    "React native",
  ];
  return (
    <main className="normal" id="start">
      <h2 className="title2">About me</h2>
      <br />
      <p>Hi I'm Robert👋 and I like to code!</p>
      <br />
      <p>
        Well, maybe that is a little reductive. As a webdeveloper and a HBO-ICT
        student I like learning about new technologies. During my study I gained
        knowledge with, among other things:
      </p>
      <ul>
        {skills.map((skill) => (
          <li>{skill}</li>
        ))}
      </ul>
      <br />
      <p>
        Besides being a student I also work as a freelance web developer for
        start ups and small companies (for more info see
        <Link to="/portfolio" className="link">
          {" "}
          portfolio
        </Link>
        ).
      </p>

      <p>
        In my spare time (when I'm not around my pc) I enjoy{" "}
        <a
          href="https://instagram.com/nxttx"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          photography
        </a>
        ,{" "}
        <a
          href="https://www.strava.com/athletes/19646647"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          running
        </a>
        , survival-ling, traveling and almost anything in the outdoors.
      </p>
      <br />
      <p>
        From here the boring stuff starts(Curriculum vitae). Beware. This is in
        dutch, in a future update this will be updated to English.
      </p>
      <br />
      <h3 className="title3">Opleidingen</h3>
      <br />
      <h4 className="title4">HBO web-development</h4>
      <p>
        <strong>Hogeschool van Arnhem en Nijmegen</strong>
      </p>
      <p>
        <i>2020 - Heden</i>
      </p>
      <br />
      <h4 className="title4">HBO ICT propedeuse</h4>
      <p>
        <strong>Hogeschool van Arnhem en Nijmegen</strong>
      </p>
      <p>
        <i>2019 - 2020</i>
      </p>
      <br />
      <h4 className="title4">HAVO</h4>
      <p>
        <strong>Montessori College Arnhem</strong>
        <br />
        Economics and society
      </p>
      <p>
        <i>2017 - 2019</i>
      </p>
      <br />
      <h3 className="title3">Werk ervaring </h3>
      <br />
      <h4 className="title4">Bediening</h4>
      <p>
        <strong>Vredebergkerk Oosterbeek</strong>
      </p>
      <p>
        Bij de Vredebergkerk in Oosterbeek werk ik als Bediening voor de
        verhuurde zalen. Hier neem ik bestellingen op, sta ik achter de bar en
        serveer ik de bestellingen uit.
      </p>
      <ul>
        <li>Bestellingen opnemen.</li>
        <li>Bestellingen uitserveren.</li>
        <li>Afrekenen.</li>
        <li>Bardiensten</li>
      </ul>
      <p>
        <i>Feb 2016 tot heden</i>
      </p>
      <br />
      <h4 className="title4">Food runner</h4>
      <p>
        <strong>’t Pannenkoekhuis, Schaarsbergen</strong>
      </p>
      <p>
        Bij 't Pannenkoekhuis in schaarsbergen heb ik gewerkt als foodrunner.
        Hier ben ik bezig geweest met het brengen van bestellingen van de bar en
        de keuken naar de verschillende tafels.
      </p>
      <ul>
        <li>Bestellingen opnemen.</li>
        <li>Bestellingen uitserveren.</li>
        <li>Afrekenen.</li>
        <li>Bardiensten</li>
      </ul>
      <p>
        <i>Jul 2019 – Dec 2019</i>
      </p>
      <br />
      <h3 className="title3">Vrijwilligerswerk Ervaring </h3>
      <br />
      <h4 className="title4">Public Relations</h4>
      <p>
        <strong>SV-Xtend</strong>
      </p>
      <p>
        Bij SV-Xtend werk ik samen in een team om de publieke relaties van de
        studievereniging te onderhouden. Denk hierbij aan:
      </p>
      <ul>
        <li>Fotograferen.</li>
        <li>facebook onderhouden, instagram beheren.</li>
        <li>Reclame maken.</li>
      </ul>
      <p>
        <i>aug 2019 - Heden</i>
      </p>
      <br />
      <h4 className="title4">Staff teamlid</h4>
      <p>
        <strong>AOT en Winter AOT</strong>
      </p>
      <p>
        De AOT is een jaarlijkse tweedaagse oriëntatietocht door de Belgische,
        Luxemburgse of Duitse Ardennen voor scouts uit heel Nederland. Hierbij
        help ik met:
      </p>
      <ul>
        <li>Navigeren, kamp opbouw, koken.</li>
        <li>Bardienst, kamp afbreken.</li>
        <li>Posten uitzetten en posten bemannen.</li>
      </ul>
      <p>
        <i> 2016 - heden</i>
      </p>
      <br />
      <h4 className="title4">Scouting leiding</h4>
      <p>
        <strong>Scouting OPV-Schoonoord</strong>
      </p>
      <p> Leiding Padvindsters (leeftijdsgroep 10-14 jaar).</p>
      <ul>
        <li>Organiseren Opkomsten, Kampen en activiteiten.</li>
        <li>Omgaan met pestgedrag en oplossen ruzies.</li>
        <li>Beheer van materiaal.</li>
      </ul>
      <p>
        <i>dec 2018 - dec 2020</i>
      </p>
      <br />
      <h4 className="title4">Aspirant leiding</h4>
      <p>
        <strong>Scouting OPV-Schoonoord</strong>
      </p>
      <p> Aspirant leiding bij verschillende leeftijdsgroepen. Waaronder:</p>
      <ul>
        <li>Bevers (gemixed, leeftijd vijf tot zes jaar).</li>
        <li>Haveli ( meisjes, leeftijd zes tot elf jaar ).</li>
        <li>Wolven (Jongens, leeftijd zes tot elf jaar).</li>
        <li>Padvindsters ( meisjes, leeftijd elf tot vijftien jaar).</li>
        <li>Verkenners (jongens, leeftijd elf tot vijftien jaar).</li>
      </ul>
      <p>
        <i>sep 2018 - dec 2018</i>
      </p>
      <br />
      <h3 className="title3">Trainingen </h3>
      <br />
      <h4 className="title4">Gekwalificeerd leiding training</h4>
      <p>
        <strong>Scouting Nederland</strong>
      </p>
      <p>Eerste en tweede trainingsweekend.</p>
      <ul>
        {/* <li>
          Maken, voorbereiden, uitvoeren en evalueren (met de overige teamleden)
          van wekelijkse programma’s, weekendkamp(en) en/of het jaarlijkse
          zomerkamp voor de groep jeugdleden.
        </li> */}
        <li>Begeleiden van jeugdleden in hun persoonlijke ontwikkeling.</li>
        <li>
          Uitvoeren van functiegebonden en organisatorische taken en
          activiteiten (zoals overleg binnen groep en regio en werving van
          nieuwe teamleden).
        </li>
        <li>
          Zich inzetten voor het verbeteren van het eigen functioneren en dat
          van het team
        </li>
      </ul>
      <p>
        <i>2019 - 2020</i>
      </p>
      <br />
    </main>
  );
}
export default About;
