import React from "react"

/**
 * Builds my portfolio
 *
 * @author Robert Boudewijn
 * @date 2021/04/08
 * @param {*} props
 * @return {*} 
 */
function Portfolio(props) {

  let dataset = [
    { "nr": "28", "title": "Quizzer", "description": "Quizzer is een applicatie om bij allerlei activiteiten een interactief onderdeel toe te voegen. Namelijk een quiz! Quizzer kan gebruikt worden bij feesten, in de kroeg, op school, maar ook tijdens een avond met de familie of voor ouderen in woongroepen.", "pageurl": "https://p004.develop.robertboudewijn.nl/index.html", "pictureurl": "images/quizzer.jpg" }, 
    { "nr": "8", "title": "Paul John Peters", "description": "Custom website design and build for Paul John Peters. ", "pageurl": "https://pauljohnpeters.nl", "pictureurl": "/images/pjp.jpg" }, 
    { "nr": "7", "title": "Design Reno", "description": "Website construction on behalf of DesignReno, a dutch kitchen renovation company.", "pageurl": "https://designreno.nl", "pictureurl": "/images/designreno.jpg" }, 
    { "nr": "6", "title": "Anne", "description": "This website was originally built for an architect. But sold to Anne. Now it is a portfolio web page.", "pageurl": "https://anneverdurmen.nl", "pictureurl": "images/anne.jpg" }, 
    { "nr": "5", "title": "Markatakas", "description": "Website build for Markatakas. A film maker and world traveler.", "pageurl": "https://markatakas.nl", "pictureurl": "images/markatakas.jpg" }, 
    { "nr": "4", "title": "Houtklover Derksen", "description": "Website construction on behalf of HoutKlover Derksen.", "pageurl": "https://p002.develop.robertboudewijn.nl", "pictureurl": "images/houtkloverderksen.jpg" }, 
    { "nr": "3", "title": "Design Tek", "description": "Website construction on behalf of Design Tek, a dutch company that deals with individuals as well as company's for interior design and realization.", "pageurl": "http://www.design-tek.nl", "pictureurl": "images/designtek.jpg" }, 
    { "nr": "2", "title": "Gospelkoor YES", "description": "Basic website for gospelkoor YES from Oosterbeek. ", "pageurl": "http://www.gospelkooryes.nl", "pictureurl": "images/gospelkooryes.jpg" }, 
    { "nr": "1", "title": "Foxels", "description": "Build a basic corporate website for my own starting company.", "pageurl": "https://foxels.nl", "pictureurl": "images/foxels.jpg" }
  ]
  // console.log(dataset)

  function generateColumns() {

    return (
      dataset.map(
        (data) => {
          return (
            <div className="CS_column project" key={data.nr}>
              <img alt="Project" className="portfolioPicture" src={data.pictureurl} />
              <h3 className="title3">{data.title}</h3>
              <p className="projectDescription">{data.description}</p><br />
              <p><a href={data.pageurl} className="link" target="_blank" rel="noreferrer"> See more...</a></p>
            </div>
          )
        }
      )
    )
  }
  return (
    <main className="normal" id="start">
      <h2 className="title2">Portfolio</h2><br />
      <div className="CS_columns">
        {generateColumns()}
      </div>
      <br />
      <h2 className="title2">My coding experience </h2>
      <ul>
        <li>Javascript front-end (react, redux)</li>
        <li>HTML en CSS front-end</li>
        <li>Javascript, PHP en Java back-end (NodeJS)</li>
        <li>NOSQL Databases (MongoDb)</li>
        <li>SQL databases (MSSQL MYSQL)</li>
        <li>Java, Processing, Arduino </li>
        <li>UML en C4 </li>
      </ul>
    </main>

  )
}

export default Portfolio